import "@hotwired/turbo-rails"

document.addEventListener("turbo:frame-missing", (event) => {
  event.preventDefault()

  let alert = document.createElement("div")
  alert.className = "alert alert-danger alert-dismissible fade show"
  alert.role = "alert"
  alert.textContent = `Content missing! ${event.detail.response.status} ${event.detail.response.statusText}`

  let button = alert.appendChild(document.createElement("button"))
  button.type = "button"
  button.className = "btn-close"
  button.dataset.bsDismiss = "alert"

  event.target.prepend(alert)
})
