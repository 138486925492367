import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["target", "template"]
  static values = {
    wrapperSelector: {
      type: String,
      default: '.nested-fields'
    }
  }

  add(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString()
    )
    this.targetTarget.insertAdjacentHTML("beforeend", content)
  }

  remove(event) {
    event.preventDefault()

    const wrapper = event.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.style.display = "none";
      wrapper.querySelector("input[name*='_destroy']").value = 1;
    }
  }
}
