import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs/modular/sortable.core.esm.js"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.url = this.element.dataset.url
    this.param = this.element.dataset.param || "ids"

    this.sortable = Sortable.create(this.element, {
      onEnd: () => { this.updatePosition() }
    })
  }

  updatePosition() {
    const elements = Array.from(this.element.querySelectorAll(":scope > [data-id]"))
    const ids = elements.map(element => element.dataset.id)
    const xhr = new XMLHttpRequest()

    xhr.open('POST', this.url)
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
    xhr.setRequestHeader("X-CSRF-Token", document.querySelector("meta[name=csrf-token]").content)
    xhr.send(JSON.stringify({ [this.param]: ids }))
  }
}
