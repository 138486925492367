import "@rails/actiontext"
import Trix from "trix"
import IconSprite from "../../assets/images/icons-editor.svg";

const lang = Trix.config.lang

lang.code = "Code Block"
lang.codeInline = "Code"
lang.mark = "Mark"
lang.heading1 = "Heading 1"
lang.heading2 = "Heading 2"
lang.heading2 = "Heading 2"
lang.underline = "Underline"

Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.textAttributes.underline = {
  tagName: "u",
  inheritable: true,
  parser: (element) => {
    let style = window.getComputedStyle(element)
    return style["textDecoration"] === "underline"
  }
}

Trix.config.textAttributes.codeInline = {
  tagName: "code",
  inheritable: true
}

Trix.config.textAttributes.mark = {
  tagName: "mark",
  inheritable: true
}

const icon = name => {
  return `<svg class="icon icon-${name}"><use xlink:href="${IconSprite}#${name}"></use></svg>`
}

Trix.config.toolbar.getDefaultHTML = () => {
  return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${icon('bold')}</button>
        <button type="button" class="trix-button" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${icon('italic')}</button>
        <button type="button" class="trix-button" data-trix-attribute="underline" data-trix-key="u" title="${lang.underline}" tabindex="-1">${icon('underline')}</button>
        <button type="button" class="trix-button" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${icon('strike')}</button>
        <button type="button" class="trix-button" data-trix-attribute="mark" data-trix-key="shift+h" title="${lang.mark}" tabindex="-1">${icon('mark')}</button>
        <button type="button" class="trix-button" data-trix-attribute="codeInline" data-trix-key="shift+m" title="${lang.codeInline}" tabindex="-1">${icon('code')}</button>
        <button type="button" class="trix-button" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${icon('link')}</button>
      </span>

      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${icon('h1')}</button>
        <button type="button" class="trix-button" data-trix-attribute="heading2" title="${lang.heading2}" tabindex="-1">${icon('h2')}</button>
        <button type="button" class="trix-button" data-trix-attribute="heading3" title="${lang.heading3}" tabindex="-1">${icon('h3')}</button>
        <button type="button" class="trix-button" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${icon('quote')}</button>
        <button type="button" class="trix-button" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${icon('code')}</button>
        <button type="button" class="trix-button" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${icon('list-ul')}</button>
        <button type="button" class="trix-button" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${icon('list-ol')}</button>
        <button type="button" class="trix-button" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${icon('outdent')}</button>
        <button type="button" class="trix-button" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${icon('indent')}</button>
      </span>
      <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
        <button type="button" class="trix-button" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${icon('attachFiles')}</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${icon('undo')}</button>
        <button type="button" class="trix-button" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${icon('redo')}</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>
  `
}
