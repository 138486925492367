import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="form--grocery"
export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      searchField: ["text", "search"]
    })
  }

  disconnect() {
    this.element.tomselect?.destroy()
  }
}
