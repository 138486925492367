import { Controller } from "@hotwired/stimulus"
import { registerEvents } from "../mixin/events"

// Connects to data-controller="trix"
export default class extends Controller {
  connect() {
    registerEvents(this, ["paste", "trix-change"])
  }

  paste(event) {
    const pastedText = event.clipboardData?.getData?.("Text")?.trim()
    if (!!pastedText && !!pastedText.match(/^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})?$/ig)) {
      this.pasteUrl(pastedText)
    }
  }

  trixChange(_event) {
    this.autoformat()
  }

  pasteUrl(pastedText) {
    const editor = this.element.editor
    let currentText = editor.getDocument().toString()
    let currentSelection = editor.getSelectedRange()
    let textWeAreInterestedIn = currentText.substring(0, currentSelection[0])
    let startOfPastedText = textWeAreInterestedIn.lastIndexOf(pastedText)
    editor.recordUndoEntry("Auto Link Paste")
    editor.setSelectedRange([startOfPastedText, currentSelection[0]])
    editor.activateAttribute('href', pastedText)
    editor.setSelectedRange(currentSelection)
  }

  autoformat() {
    // https://github.com/basecamp/trix/issues/314#issuecomment-260157037
    // https://github.com/basecamp/trix/issues/644#issuecomment-892236710
    const editor = this.element.editor

    if (editor.attributeIsActive("code")) return

    const position = editor.getPosition()
    const text = editor.getDocument().toString()
    const character = text.charAt(position - 1)
    const before = "\n" + text.substring(0, position) // always adds newline in front
    // const after = text.substring(position, text.length)

    if (before.match(/\n[*-] $/)) {
      editor.recordUndoEntry("autoformat-bullet")
      editor.setSelectedRange([position - 2, position])
      editor.deleteInDirection("forward")
      editor.activateAttribute("bullet")

    } else if (before.match(/\n1\. $/)) {
      editor.recordUndoEntry("autoformat-number")
      editor.setSelectedRange([position - 3, position])
      editor.deleteInDirection("forward")
      editor.activateAttribute("number")

    } else if (before.match(/\n> $/)) {
      editor.recordUndoEntry("autoformat-quote")
      editor.setSelectedRange([position - 2, position])
      editor.deleteInDirection("forward")
      editor.activateAttribute("quote")

    } else if (before.match(/\n(#+) $/)) {
      const level = before.match(/\n(#+) $/)[1].length

      editor.recordUndoEntry("autoformat-heading")
      editor.setSelectedRange([position - (1 + level), position])
      editor.deleteInDirection("forward")
      editor.activateAttribute(`heading${level}`)

    } else if (character == "`") {
      editor.recordUndoEntry("autoformat-code-inline")
      editor.deleteInDirection("backward")

      if (editor.attributeIsActive("codeInline")) {
        editor.deactivateAttribute("codeInline")
        editor.insertString(" ")
      } else {
        editor.activateAttribute("codeInline")
      }
    }
  }
}
