import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="form--unit"
export default class extends Controller {
  connect() {
    new TomSelect(this.element, {})
  }

  disconnect() {
    this.element.tomselect?.destroy()
  }
}
