const camalize = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const registerEvents = (controller, events) => {
  const registeredEvents = events.map(name => {
    const func = controller[camalize(name)].bind(controller)
    controller.element.addEventListener(name, func)
    return { name: name, func: func }
  })

  const deregisterEvents = () => {
    registeredEvents.forEach(event => {
      controller.element.removeEventListener(event.name, event.func)
    })
  }

  const controllerDisconnect = controller.disconnect.bind(controller)

  Object.assign(controller, {
    disconnect() {
      deregisterEvents()
      controllerDisconnect()
    }
  })
}
